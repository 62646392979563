<template>
  <div>
    <kafkaSelect @kafkaChange="kafkaChange"></kafkaSelect>

    <el-table :data="tableData" stripe border>
      <el-table-column prop="id" label="broker id">
        <template slot-scope="scope">
          <span style="margin-right: 5px">{{ scope.row.id }}</span>
          <el-tag type="danger" v-if="scope.row.controller" effect="dark" size="mini">controller</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="host" label="host"></el-table-column>
      <el-table-column prop="port" label="端口"></el-table-column>
    </el-table>
  </div>
</template>

<script>
import kafkaSelect from '../kafkaSelect.vue'
import axios from "axios";

export default {
  name: "cluster",
  data() {
    return {
      sourceId: null,
      tableData: [],
      auth: { add: false, update: false, remove: false }

    }
  },
  methods: {

    getCluster() {
      let params = new URLSearchParams();
      params.append("sourceId", this.sourceId);
      axios.post("/kafka/cluster/info", params).then((response) => {
        console.log("集群管理的返回值",response.data);
        if (response.data.success)
          this.tableData = (response.data.data)
        else
          this.$message.error(response.data.message)
      }).catch((error) => {
        this.$message.error("查询集群信息失败")
      })
    },
    kafkaChange(sourceId) {
      this.sourceId = sourceId
      // this.auth = this.$store.getters.getKafkaAuth(sourceId)
      this.getCluster()
    },
  },
  components: {
    kafkaSelect
  }
}
</script>

<style scoped>

</style>